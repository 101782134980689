<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>营地管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>

        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>营地筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="营地名称" prop="name">
                            <el-input
                                v-model="queryForm.name"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="营地类型" prop="type">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.type"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_campType"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getCampList"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="queryReset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>营地列表</span>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <el-button type="primary" @click="goCampDetail('')"
                            >新增营地</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                :data="campList"
                header-cell-class-name="tableHeaderStyle"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name">
                    <template slot-scope="scope">
                        <el-link
                            type="primary"
                            @click="
                                goCampCampersData(scope.row.id)
                            "
                            >{{ scope.row.name }}</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="营地类型"
                    prop="type"
                    :formatter="campTypeFormatter"
                ></el-table-column>
                <el-table-column label="地点" prop="place"></el-table-column>
                <el-table-column
                    label="年龄段"
                    prop="ageRange"
                ></el-table-column>
                <el-table-column label="时长" prop="duration"></el-table-column>
                <el-table-column
                    label="开营时间"
                    prop="startTime"
                ></el-table-column>
                <el-table-column
                    label="闭营时间"
                    prop="endTime"
                ></el-table-column>
                <el-table-column label="价格" prop="price"></el-table-column>
                <el-table-column label="是否开启" prop="closeStatus">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.closeStatus"
                            :active-value="true"
                            @change="stateChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120px">
                    <template scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="goCampDetail(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            @click="showDeleteCampTip(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { camPage, campClose, campDel } from '@/http/api'

export default {
  data () {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        type: ''
      },
      total: 0,
      dict_campType: this.$userInfo.getDataList(this, 't_camp_type').then(value => { this.dict_campType = value }),
      campList: [],
      campConsultBadge: '',
      campPayBadge: ''
    }
  },
  created () {
    this.getCampList()
    // this.getCampConsultBadge()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getCampList () {
      camPage(this.queryForm).then((res) => {
        this.campList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    stateChange (rowData) {
      this.$confirm('是否确认修改该状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        campClose({ id: rowData.id, closeStatus: rowData.closeStatus }).then((res) => {
          this.$message.success('状态修改成功')
          this.getCampList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getCampList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getCampList()
    },
    async getCampConsultBadge () {
      const { data: res } = await this.$http.get('/entertainment/camp/erp/getCampBadge')
      console.log('getCampConsultBadge', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.campConsultBadge = res.data.campConsultBadge
      this.campPayBadge = res.data.campPayBadge
    },
    queryReset () {
      this.$refs.queryFormRef.resetFields()
    },
    goCampCampersData (id) {
      this.$router.push(
        {
          path: '/raceManagement/camp/campCampersData',
          query: {
            campId: id
          }
        }
      )
    },
    goCampDetail (id) {
      this.$router.push(
        {
          path: '/raceManagement/camp/campDetailsData',
          query: {
            campId: id
          }
        }
      )
    },
    showDeleteCampTip (id) {
      this.$confirm('此操作将删除该营地, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        campDel(id).then((res) => {
          this.$message.success('删除成功')
          this.getCampList()
        }).catch((err) => {
          console.log('err', err)
        })
        // const { data: res } = await this.$http.get('/entertainment/app/deleteCamp', {
        //   params: {
        //     campId: id
        //   }
        // })
        // console.log('showDeleteCampTip', res)
        // if (res.code !== 0) {
        //   return this.$message.error(res.message)
        // }
        // this.$message.success('删除成功')
        // this.getCampList()
      }).catch(() => { })
    },
    // // 咨询列表
    // goInquiryForm () {
    //   this.$router.push('/match_thing_manage/camp_overseas/camp_inquiry_form')
    // },
    // // 报名列表
    // goRegistrationList () {
    //   this.$router.push('/match_thing_manage/camp_overseas/camp_registration_list')
    // },
    campTypeFormatter (row, column, cellValue) {
      var temp = '未知'
      for (let index = 0; index < this.dict_campType.length; index++) {
        const element = this.dict_campType[index]
        if (element.dictValue === (cellValue + '')) {
          return element.dictLabel
        }
      }
      return temp
    }
  }
}
</script>

<style>
.button-right {
    margin-left: 20px;
}
</style>
